<template>
  <div class="footer">
    <v-footer
      app
      :padless="true"
      v-if="isFixed"
    >
      <FooterContent />
    </v-footer>
    <v-footer
      v-else
      :padless="true"
    >
      <FooterContent />
    </v-footer>
  </div>
</template>

<script>
import FooterContent from "@/components/FooterContent"

export default {
  data: () => ({
  }),
  computed: {
    isFixed () {
      if (['xs', 'sm'].includes(this.$vuetify.breakpoint.name)) return false
      return ['page'].includes(this.$route.name)
    }
  },
  components: { FooterContent }
}
</script>
