// based on:
// https://medium.com/i18n-and-l10n-resources-for-developers/the-ultimate-guide-to-vue-localization-with-vue-i18n-bf98b1d40c65

import Vue from 'vue'
import App from './App.vue'
import i18n from './i18n'
import vuetify from './plugins/vuetify';
import router from './router'
import store from './store'

// apollo + graphql
// https://hasura.io/learn/graphql/vue/apollo-client/
import VueApollo from "vue-apollo";
import ApolloClient from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";

// imports
import ReactiveSearch from '@appbaseio/reactivesearch-vue'
import VueYoutube from 'vue-youtube'
import GlossaryDialog from '@/components/GlossaryDialog'
import WoaDialog from '@/components/WoaDialog'

Vue.config.productionTip = false

Vue.component('glossary-dialog', GlossaryDialog)
Vue.component('woa-dialog', WoaDialog)

// use plugins
Vue.use(ReactiveSearch)
Vue.use(VueYoutube)

Vue.prototype.$scrollToTop = () => window.scrollTo(0,0)

// apollo setup
Vue.use(VueApollo);

const getHeaders = () => {
  const headers = {};
   const token = window.localStorage.getItem('apollo-token');
   if (token) {
     headers.authorization = `Bearer ${token}`;
   }
   return headers;
};

// Create an http link:
const link = new HttpLink({
//   uri: 'http://folkme.local/api',
   uri: process.env.VUE_APP_GQL_API_ENDPOINT,
   fetch,
   headers: getHeaders()
});

const client = new ApolloClient({
   link: link,
   cache: new InMemoryCache({
     addTypename: true
   })
});

const apolloProvider = new VueApollo({
  defaultClient: client,
})

// custom mixins
Vue.mixin({
  methods: {
    imgResized(imagePath, params={}) {
      // console.log( imagePath )
      if (process.env.VUE_APP_IMAGE_RESIZER == "weserv") {
        let ret = process.env.VUE_APP_IMAGES_WEBROOT_WESERV + imagePath
        if (typeof(params.width) !== 'undefined') ret += '&w=' + params.width
        return ret
      } else if (process.env.VUE_APP_IMAGE_RESIZER == "h3-imgprxy") {
        const createHmac = require('create-hmac')

        const KEY = '28e146135606167e2b8c910ea229f72717b6c409c08e09e5af78f501b2475530e20256dd0d3c6c1e8f573e1262e4670dd97a6c25ef6bedd97849bb772ce1a562'
        const SALT = 'e8eca213cc34224a689cae66c1218749d3709b671025d6de2aec619a16782fcc82cd4865afc47edfbb9ca4ed8dda52d88ba9138ac5b4de5b971631727bdd7a64'

        const urlSafeBase64 = (string) => {
          return Buffer.from(string).toString('base64').replace(/=/g, '').replace(/\+/g, '-').replace(/\//g, '_')
        }

        const hexDecode = (hex) => Buffer.from(hex, 'hex')

        const sign = (salt, target, secret) => {
          const hmac = createHmac('sha256', hexDecode(secret))
          hmac.update(hexDecode(salt))
          hmac.update(target)
          return urlSafeBase64(hmac.digest())
        }

        const url = process.env.VUE_APP_IMAGES_WEBROOT_H3_IMGPRXY + imagePath
        const resizing_type = 'fit'
        let width = 600
        if (typeof(params.width) !== 'undefined') { width = params.width }
        let height = 300
        if (typeof(params.height) !== 'undefined') { height = params.height }
        const gravity = 'no'
        const enlarge = 1
        const extension = 'jpg'
        const encoded_url = urlSafeBase64(url)
        const path = `/${resizing_type}/${width}/${height}/${gravity}/${enlarge}/${encoded_url}.${extension}`

        const signature = sign(SALT, path, KEY)
        const result = `/${signature}${path}`
        // console.log(result)
        return 'https://imgprxy.h3online.com' + result
      } else if (process.env.VUE_APP_IMAGE_RESIZER == "h3-pcd") {
        let width = 600
        if (typeof(params.width) !== 'undefined') { width = params.width }
        let height = 300
        if (typeof(params.height) !== 'undefined') { height = params.height }

        const url = process.env.VUE_APP_IMAGES_WEBROOT_H3_PCD + `w-${width}:h-${height}/${imagePath}`
        // console.log(url)
        return url
      } else {
        const url = process.env.VUE_APP_IMAGES_WEBROOT_NONE + imagePath
        console.log( url )
        return url;

      }
    },
    mediaFile(file) {
      return process.env.VUE_APP_MEDIA_WEBROOT + file
    },
    taxonomiesToString: function (taxonomy, separator='; ') {
      var ret = ''
      var separatorLength = separator.length;
      if (separator == '_newline_') {
        separator = '<br/>'
        separatorLength = 0
      }
      
      if (typeof (taxonomy.level_1) !== 'undefined') {
        for (let level1 of taxonomy.level_1) {
          if (typeof (level1.level_2) !== 'undefined') {
            for (let level2 of level1.level_2) {
              if (typeof (level2.level_3) !== 'undefined') {
                for (let level3 of level2.level_3) {
                  ret += level1.name + ' / ' + level2.name + ' / ' + level3.name + separator
                }
              } else {
                ret += level1.name + ' / ' + level2.name + separator
              }
            }
          } else {
            ret += level1.name + separator
          }
        }
      }
      ret = ret.substring(0, ret.length - separatorLength)
      return ret
    },
    ucfirst (string) {
      if (string !== null) {
        return string.charAt(0).toUpperCase() + string.slice(1)
      }
    },
    spacer () {
      return '<img src="https://img.spacergif.org/v1/spacer.gif" width="1" height="1">';
    },
    elasticEndpoint () {
      return process.env.VUE_APP_ELASTIC_ENDPOINT;
    },
    locationToString (item, fields = {}) {
      if (typeof (fields.location) === 'undefined') fields.location = item.location_1
      if (typeof (fields.location_now) === 'undefined') fields.location_now = item.location_2
      if (typeof (fields.county) === 'undefined') fields.county = item.location_3

      var location = ''
      var location_now = ''
      var county = ''
      // console.log(item)
      if (typeof (fields.location_now) !== 'undefined') {
        if (typeof (fields.location_now.level_1) !== 'undefined') {
          for (let level1 of fields.location_now.level_1) {
            location_now += level1.name
            location_now += '; '
          }
          location_now = location_now.substring(0, location_now.length - 2)
        }
      }
      if (typeof (fields.location) !== 'undefined') {
        if (typeof (fields.location.level_1) !== 'undefined') {
          for (let level1 of fields.location.level_1) {
            location += level1.name
            location += '; '
          }
          location = location.substring(0, location.length - 2)
        }
      }

      var ret = location
      if (location_now !== location && location_now !== '') ret += ' [' + location_now + ']'

      if (typeof (fields.county) !== 'undefined') {
        if (typeof (fields.county.level_1) !== 'undefined') {
          for (let level1 of fields.county.level_1) {
            county += level1.name
            county += '; '
          }
          county = county.substring(0, county.length - 2)
        }
      }

      if (ret == '') {
        if (county !== '') ret = '(' + county + ')'
      } else {
        if (county !== '') ret += ' (' + county + ')'
      }

      return ret
    },
    itemLink (type, id) {
      return '/' + this.$i18n.locale + '/item/' + type + '/' + id
    }
  }
})


new Vue({
  i18n,
  vuetify,
  router,
  apolloProvider,
  store,
  render: h => h(App)
}).$mount('#app')
